import React, { useState, useEffect } from "react"
import { Dialog } from "@blueprintjs/core"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import { Button, ButtonGroupMap } from "../../components/Button"
import { Text, Image } from "../../components/Core"
import CloseIcon from "../../components/CloseIcon"
import { Section } from "../../components/Containers"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft"
import { faChevronSquareUp } from "@fortawesome/pro-duotone-svg-icons/faChevronSquareUp"

const Services = ({ post }) => {
  if (!post.hasThisSection) return ""

  const { width } = useWindowDimensions()

  const [activeItem, setActiveItem] = useState(post.items[0])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      document.querySelector("html").style.overflowY = "hidden"
    } else {
      document.querySelector("html").style.removeProperty("overflow-y")
    }

    return () => {
      document.querySelector("html").style.removeProperty("overflow-y")
    }
  }, [isModalOpen])

  const handleServiceChange = service => {
    setActiveItem(service)
    const el = document.getElementsByClassName("services-menu")[0]
    window.scroll({ top: el.offsetTop - 100, left: 0, behavior: "smooth" })
  }

  const handleModal = service => {
    if (width <= 767) {
      setActiveItem(service)
      setIsModalOpen(true)
    }
  }

  const scrollUp = () => {
    const el = document.querySelector("#modal-top")
    //window.scroll({ top: (el.offsetTop - 100), left: 0, behavior: 'smooth' });
    el.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <Section colorBack>
      <div className="home-services">
        <div className="columns">
          <div className="column is-3"></div>
          <div className="column" data-aos-duration="1200" data-aos="fade-left">
            <h2 className="has-text-centered-tablet h3-style">
              {post.heading}
            </h2>

            <Text text={post.blurb} className="home-services-blurb" />

            <div className="services-menu">
              <div className="services-menu-list">
                {post.items.map((item, i) => (
                  <div
                    className="service-item"
                    key={i}
                    onClick={() => handleModal(item)}>
                    <Image publicId={item.imageId} responsiveUseBreakpoints />
                    <Text
                      as="p"
                      className="service-item-heading is-hidden-tablet is-hidden-desktop"
                      text={item.heading}
                    />
                    <div
                      className={`service-icon ${
                        item.heading === activeItem.heading ? "icon-active" : ""
                      }`}
                      onClick={() => handleServiceChange(item)}>
                      <FontAwesomeIcon
                        className="button-icon"
                        icon={faChevronLeft}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="services-menu-content">
                {post.items.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item.heading === activeItem.heading
                        ? "active-content"
                        : "hidden-content"
                    }>
                    <Text
                      as="p"
                      className="service-item-heading"
                      text={item.heading}
                    />
                    <Text text={item.blurb} />

                    <Button
                      buttonText={item.button.title}
                      href={item.button.href}
                      appearance="contained"
                    />
                  </div>
                ))}
              </div>
            </div>

            <ButtonGroupMap isCentered buttons={post.buttons} />
          </div>
          <div className="column is-3"></div>
        </div>
      </div>

      <Dialog
        id="modal-top"
        canOutsideClickClose={true}
        enforceFocus={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        isOpen={isModalOpen}
        usePortal={true}
        onClose={() => setIsModalOpen(false)}
        className="anesthesia-dialog modal-top">
        <div className="dialog-content services-modal">
          <div
            id="modal-top"
            style={{ position: "absolute", top: "-100px", left: "0" }}></div>

          <CloseIcon onClick={() => setIsModalOpen(false)} />
          <p className="service-item-heading">{activeItem.heading}</p>
          <Text text={activeItem.blurb} />

          <Button
            className="mx-auto"
            buttonText={activeItem.button.title}
            href={activeItem.button.href}
            appearance="contained"
          />

          <div className="scroll-up" onClick={scrollUp}>
            <FontAwesomeIcon className="button-icon" icon={faChevronSquareUp} />
          </div>
        </div>
      </Dialog>
    </Section>
  )
}

export default Services
