import React from "react"
import classNames from "classnames"

import TheaterVideo from "@components/Theater/TheaterVideo"
import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import { Section, Columns } from "../../components/Containers"
import { Text, Image } from "../../components/Core"

function ReferringProvider(props) {
  if (!props.data.hasThisSection) {
    return <div />
  }

  const mainEnglishClasses = classNames("continuum", "is-vcentered", {
    "color-back--gray": props.colorBack
  })

  const post = props.data
  const language = props.language

  if (props.textOnly) {
    return (
      <Section
        colorBack={props.colorBack}
        className={props.colorBack ? "color-back--gray" : ""}>
        <Columns sideColumnsSize={3}>
          <div className="column">
            <Text
              className="mt-0 has-text-centered"
              as="h3"
              text={post.heading}
            />
            <Text as="p" text={post.text} />
          </div>
        </Columns>
      </Section>
    )
  }

  return (
    <Section colorBack={props.colorBack} className={mainEnglishClasses}>
      <HorizontalTwoColumns
        leftColWidth={4}
        rightColWidth={4}
        middleColWidth={1}
        className="is-vcentered"
        leftColumn={
          <div
            className="column is-9"
            style={{ margin: "auto 0" }}
            data-aos="fade-right"
            data-aos-duration="1200">
            <Text as="h2" className="h3-style mt-0 pb-0" text={post.heading} />
            <Text className="pb-0" text={post.text} />
            <div className="referring-provider-theater-button-desktop">
              <TheaterVideo
                buttonClass="contained"
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls
                playing
                hasWatchVideo
                language={language}
              />
            </div>
          </div>
        }
        rightColumn={
          <div className="column" data-aos="fade-left" data-aos-duration="1200">
            <Image className="mt-2--mobile" publicId={post.imageId} />
            <p
              style={{
                color: "#6d6e6f",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "bold",
                marginTop: "19px",
                marginBottom: "0"
              }}>
              {post.imageCaption}
            </p>
            <div className="referring-provider-theater-button-mobile">
              <TheaterVideo
                buttonClass="contained mx-auto"
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls
                playing
                hasWatchVideo
                language={language}
              />
            </div>
          </div>
        }
      />
    </Section>
  )
}

export default ReferringProvider
